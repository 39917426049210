<template>
  <div class="account-records">
    <div class="account-records-aside">
      <AccountFortuneGroup
        ref="account-fortune-group"
        @handleFortune="handleFortune"
        @onWithdraw="onWithdraw"
        @onCashExchange="onCashExchange"
        :fortunes="lastList"
        :isTotalSum="isTotalSum"
      />
    </div>
    <div class="account-records-container">
      <div class="account-records-container-treasury">
        <div class="treasury-title">
          <div class="line-left"></div>
          <div class="title-text">
            <i class="title-icon"></i>
            <span>{{ username }}</span
            >的小金库
          </div>
          <div class="line-right"></div>
        </div>
        <div class="treasury-tip">
          蚊子腿是一种态度 · 关键还是喜欢这种一劳永逸的感觉 ·
          挥一挥衣袖就不去想流芳百世了
        </div>
        <div class="treasury-bottom">
          <div class="treasury-group">
            <div
              @click="handleCenter(item.id)"
              class="treasury-group-item"
              v-for="item in centerList"
              :key="item.id"
              :class="item.id == 1 ? 'purple' : 'green'"
            >
              <div class="info">
                <div class="title">{{ item.title }}</div>
                <div class="num" v-if="item.id == 1">{{ item.num }}</div>
                <div class="num" v-else>
                  <span>{{ item.num }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="query-wrap">
        <div class="time-search">
          <span class="time-txt">时间:</span>
          <el-date-picker
            v-model="queryStartTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="开始时间"
          >
          </el-date-picker>
          <el-date-picker
            v-model="queryEndTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="结束时间"
          >
          </el-date-picker>
          <div class="search-btn" @click="findRevenueExpendInfo">搜索</div>
        </div>
        <div class="query-records">
          <el-select
            v-model="selectedOption"
            :placeholder="queryRecordsLang[0].value"
            @change="changeRecordsOption"
          >
            <el-option
              v-for="item in queryRecordsLang"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        :class="[
          'account-records-container-table',
          { 'account-records-container-table-default': !incomeReport.length },
        ]"
      >
        <el-table
          :data="incomeReport"
          style="width: 100%"
          v-if="incomeReport.length"
        >
          <el-table-column label="序号" width="50" align="center">
            <template slot-scope="{ $index }">{{ $index + 1 }}.</template>
          </el-table-column>
          <!-- <el-table-column width="20"></el-table-column> -->
          <el-table-column
            show-overflow-tooltip
            prop="remark"
            label="内容"
            width="450"
            align="center"
          >
          </el-table-column>
          <!-- <el-table-column width="120"></el-table-column> -->
          <el-table-column label="字符/金额" width="150" align="center">
            <template slot-scope="{ row: { quantity } }"
              >+{{ quantity }}</template
            >
          </el-table-column>
          <!-- <el-table-column width="128"></el-table-column> -->
          <!-- <el-table-column width="120"></el-table-column> -->
          <el-table-column label="时间" align="center">
            <template slot-scope="{ row: { createTime: time } }">{{
              time
            }}</template>
          </el-table-column>
        </el-table>
      </div>
      <div class="account-records-container-footer">
        <el-pagination
          background
          :hide-on-single-page="true"
          layout="prev,pager,next"
          prev-text="上一页"
          next-text="下一页"
          :pager-count="5"
          :page-size="pageSize"
          :page-count="pageCount"
          :current-page="pageNum"
          @current-change="currentChange"
        />
      </div>
    </div>
    <AccountRecordsDialog
      ref="account-records-dialog"
      :center-list="centerList"
      :last-list="lastList"
      @updateRevenue="updateRevenue"
      :systemCHSum="systemCHSum"
    />
  </div>
</template>

<script>
import AccountFortuneGroup from "components/account-records/AccountFortuneGroup";
import AccountRecordsDialog from "components/account-records/AccountRecordsDialog";
import {
  findRevenue,
  findCopyrightIntegral,
  findCopyrightIntegralOrder,
  findRevenueExpendInfo,
} from "api/copyrightIncome";
export default {
  name: "AccountRecords",
  data() {
    return {
      //筛选记录选项
      queryRecordsLang: [
        {
          id: 1,
          value: "版权收入记录",
        },
        {
          id: 2,
          value: "版权支出记录",
        },
      ],
      centerList: [
        {
          id: 1, //1权益积分 2排行榜
          num: 0,
          title: "权益积分",
        },
        {
          id: 2,
          num: null,
          title: "江湖排行榜",
        },
      ],
      selectedOption: "版权收入记录",
      //开始时间
      queryStartTime: "",
      //结束时间
      queryEndTime: "",
      //用户名
      username: "",
      //收益报表
      incomeReport: [],
      pageNum: 1,
      pageSize: 6,
      pageCount: 1,
      //弹窗组件
      dialog: null,
      //资金按钮组件
      fortuneRef: null,
      //资金类型
      fortuneType: 1,
      //报表类型
      reType: 1,
      lastList: [
        {
          id: 1, //1现金  2字符包
          num: 0,
        },
        {
          id: 2,
          num: 0,
        },
        {
          id: 6,
          num: 0,
        },
        {
          id: 7,
          num: 0,
        },
      ],
      // 总字符
      isTotalSum: 0,
      // 购买/系统赠送字符
      systemCHSum: 0,
    };
  },
  methods: {
    //查询重置项
    queryReset() {
      this.queryStartTime = "";
      this.queryEndTime = "";
      this.pageNum = 1;
    },
    //切换资金报表类型
    handleFortune(t) {
      this.fortuneType = t;
      this.queryReset();
      this.findRevenueExpendInfo();
    },
    //点击提现
    onWithdraw() {
      this.dialog.val = 1;
      this.dialog.dialogVisible = true;
    },
    //点击兑现
    onCashExchange() {
      this.dialog.val = 2;
      this.dialog.dialogVisible = true;
    },
    //筛选记录值改变
    changeRecordsOption(val) {
      this.selectedOption = val;
      this.queryRecordsLang.forEach((i) => {
        if (i.value === val) {
          this.reType = i.id;
        }
      });
      this.queryReset();
      this.findRevenueExpendInfo();
    },
    //当前页改变
    currentChange(cur) {
      this.pageNum = cur;
      this.findRevenueExpendInfo();
    },
    //点击积分/排名
    handleCenter(val) {
      this.dialog.centerVal = val;
      this.dialog.centerVisible = true;
    },
    //更新收益资金
    updateRevenue() {
      this.getRevenue();
    },
    //获取现金、字符数量
    async getRevenue() {
      const res = await findRevenue();
      if (res.code === 200) {
        let info = res.data;

        if (info) {
          this.lastList[0].num = info.copyrightIncome + info.practiceIncome;
          this.lastList[1].num = info.incomeCharacter;
          this.lastList[2].num = info.copyrightIncome;
          this.lastList[3].num = info.practiceIncome;
        }
        this.isTotalSum = info.buyCharacter + info.incomeCharacter;
        this.systemCHSum = info.buyCharacter;
        this.username = res.message;
        // console.log(this.isExtractSum);
      } else {
        this.$message({
          type: "error",
          message: "获取现金、字符数量" + res.message,
        });
      }
    },
    //获取积分
    async getIntegral() {
      const res = await findCopyrightIntegral();
      if (res.code === 200) {
        if (res.data.integral == 0) {
          this.centerList[0].num = "暂无";
        } else {
          this.centerList[0].num = res.data.integral;
        }
      } else {
        this.$message({
          type: "error",
          message: "获取积分" + res.message,
        });
      }
    },
    //获取排名
    async getRanking() {
      const res = await findCopyrightIntegralOrder();
      if (res.code === 200) {
        if (res.data == 0 || res.data >= 10000) {
          this.centerList[1].num = "暂无";
        } else {
          this.centerList[1].num = "第" + res.data + "名";
        }
      } else {
        this.$message({
          type: "error",
          message: "获取排名" + res.message,
        });
      }
    },
    //查询资金报表
    async findRevenueExpendInfo() {
      const params = {
        page: this.pageNum,
        limit: this.pageSize,
        reType: this.reType,
        projectType: this.fortuneType,
        startTime: this.queryStartTime,
        endTime: this.queryEndTime,
      };
      this.$store.commit("playLifeLoading", true);
      const res = await findRevenueExpendInfo(params);
      this.$store.commit("playLifeLoading", false);
      if (res.code == 200) {
        this.pageCount = Math.ceil(res.data.total / this.pageSize);
        this.incomeReport = res.data.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
  },
  components: {
    AccountFortuneGroup,
    AccountRecordsDialog,
  },
  created() {
    this.getRevenue();
    this.getIntegral();
    this.getRanking();
    this.findRevenueExpendInfo();
  },
  mounted() {
    this.dialog = this.$refs["account-records-dialog"];
    this.fortuneRef = this.$refs["account-fortune-group"];
  },
};
</script>

<style lang="scss">
.el-tooltip__popper {
  max-width: 400px;
  margin-left: 80px;
}
.el-tooltip__popper.is-dark {
  background: #fff !important;
  color: #606266 !important;
  font-size: 14px;
  box-shadow: 0 3px 3px 3px rgba(151, 151, 158, 0.5);
}
</style>

<style lang="scss" scoped>
.account-records {
  display: flex;
  min-height: calc(100vh - 126px);
  border: 1px solid #eceff1;
  border-radius: 5px;
  &-aside {
    flex-shrink: 0;
    width: 237px;
    padding: 0 20px;
    border-right: 1px solid #eceff1;
  }
  &-container {
    flex: 1;
    @include flex-center(column);
    justify-content: normal;
    align-items: normal;
    padding: 0 30px 0 22px;
    &-treasury {
      overflow: hidden;
      .treasury-title {
        @include flex-center;
        margin: 30px auto 0;
        position: relative;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        .line-left {
          position: relative;
          width: 89px;
          height: 12px;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: calc(50% - 6px);
            width: 89px;
            height: 12px;
            background: url("~assets/images/real-line-left.png") no-repeat;
            background-size: cover;
          }
        }
        .line-right {
          position: relative;
          width: 89px;
          height: 11px;
          &::before {
            content: "";
            position: absolute;
            right: 0;
            top: calc(50% - 6px);
            width: 89px;
            height: 11px;
            background: url("~assets/images/real-line-right.png") no-repeat;
            background-size: cover;
          }
        }
        .title-text {
          margin: 0 12px;
          font-size: 12px;
          font-weight: 500;
          color: #333333;
          .title-icon {
            width: 12px;
            height: 12px;
            @include backgroundGroup("~assets/images/titleIcon.png");
            margin-right: 10px;
          }
          span {
            font-size: 14px;
            font-weight: bold;
            margin-right: 10px;
          }
        }
      }
      .treasury-tip {
        margin-top: 20px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
      .treasury-bottom {
        @include flex-center;
        margin-top: 30px;
        .treasury-group {
          @include flex-start;
          &-item {
            display: flex;
            width: 198px;
            height: 79px;
            cursor: pointer;
            &.purple {
              @include backgroundGroup(
                "~assets/images/account-records/equity-points-bg.png"
              );
            }
            &.green {
              @include backgroundGroup(
                "~assets/images/account-records/jianghu-rink-bg.png"
              );
              margin-left: 10px;
            }
            &.green {
              .info {
                .title {
                  color: #ffffff;
                }
              }
            }
            .info {
              display: flex;
              flex-direction: column;
              margin-left: 70px;
              overflow: hidden;
              .title {
                margin-top: 24px;
                font-size: 12px;
                font-weight: 500;
                color: #dfdff9;
              }
              .num {
                margin-top: 9px;
                font-weight: bold;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .query-wrap {
      @include flex-between;
      margin-top: 30px;
      .time-search {
        @include flex-start;
        @include noselect;
        > div {
          margin-left: 10px;
        }
        .time-txt {
          font-size: 12px;
          font-weight: 500;
          color: #333333;
        }
        ::v-deep .el-date-editor.el-input {
          width: 140px;
          .el-input__prefix {
            display: none;
          }
          .el-input__icon {
            line-height: 30px;
          }
          input {
            height: 30px;
            padding-left: 9px;
          }
        }
        .search-btn {
          width: 51px;
          height: 30px;
          background: #4587ff;
          border-radius: 3px;
          text-align: center;
          line-height: 30px;
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          cursor: pointer;
        }
      }
      .query-records {
        ::v-deep .el-select {
          input {
            width: 160px;
            height: 30px;
            font-size: 12px;
            font-weight: 500;
            color: #333333;
          }
          .el-input__icon {
            line-height: 30px;
          }
        }
      }
    }
    &-table {
      &.account-records-container-table-default {
        background: url("~assets/images/account-records/kong.png") no-repeat;
        background-position: 50% 50%;
        background-size: 140px;
      }
      flex: 1;
      margin-top: 20px;
      ::v-deep .el-table th.el-table__cell.is-leaf {
        border-bottom: none;
      }
      ::v-deep .el-table::before {
        background-color: transparent;
      }
      ::v-deep .el-table .el-table__header th {
        &:first-child .cell {
          padding-left: 0;
        }
      }
      ::v-deep .el-table tr.el-table__row {
        td:first-child {
          .cell {
            padding-left: 0;
          }
        }
        td:last-child {
          .cell {
            // display:flex;
            // justify-content:flex-end;
            padding-right: 0;
          }
        }
      }
      ::v-deep .el-table td.el-table__cell {
        &:first-child {
          @include noselect;
        }
        border-bottom: none;
        .cell {
          @include ellipsis;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
    &-footer {
      padding: 21px 0;
      @include flex-center;
      ::v-deep .el-pagination {
        button {
          width: 80px;
        }
      }
    }
  }
}
</style>
