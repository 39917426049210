<template>
  <div class="account-records-dialog">
    <!-- 排名和积分弹窗  centerVal 1积分 2排名  -->
    <el-dialog
      :visible.sync="centerVisible"
      :modal="false"
      :close-on-click-modal="false"
      width="20%"
      center
      class="treasury-group-dialog"
    >
      <div class="center">
        <div class="title">
          <i
            class="last-box-icon"
            :class="centerVal == 1 ? 'icon_integral' : 'icon_ranking'"
          ></i>
          {{ centerVal == 1 ? "权益积分:" : "江湖排名:" }}
          <div class="integral" v-show="centerVal != 1">
            <div class="text">{{ centerList[1].num }}</div>
          </div>
          <div class="text" v-show="centerVal == 1">
            {{ centerList[0].num }}
          </div>
        </div>
        <div class="text_ranking">
          {{
            centerVal == 1
              ? "基于原创内容获得的字符包兑换现金，2.5元/万字符,累计的钱1元/积分。VIP/SVIP会员及系统赠送的字符包、不参与积分累积。"
              : "基于原创内容获得的字符包兑换现金，2.5元/万字符,累计的钱1元/积分。VIP/SVIP会员及系统赠送的字符包、不参与积分累积。"
          }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn_box">
          <div class="confirm" @click="centerVisible = false">
            {{ centerVal == 1 ? "确 定" : "年度百强" }}
          </div>
          <div class="placeholder"></div>
          <div class="cancel" @click="centerVisible = false">
            {{ centerVal == 1 ? "取 消" : "月度十强" }}
          </div>
        </div>
      </span>
    </el-dialog>
    <!-- 字符提现或者现金提现弹窗 -->
    <el-dialog
      :title="val == 1 ? '提取现金' : '字符包兑换现金'"
      :visible.sync="dialogVisible"
      :modal="false"
      :close-on-click-modal="false"
      @close="handleClose"
      width="25%"
      class="withdraw-dialog"
    >
      <div class="cash" v-show="val == 1">
        <i class="last-box-icon icon_cash"></i>

        现金余额：
        <div class="text">{{ lastList[val - 1].num }}</div>
        元
      </div>

      <div class="cahracter" v-show="val != 1">
        <i class="last-box-icon icon_cahracter"></i>

        购买/系统赠送：
        <div class="text">{{ systemCHSum }}</div>
        字 (不可兑换)
      </div>
      <div class="cahracter" v-show="val != 1">
        <i class="last-box-icon icon_cahracter"></i>

        收益字符：
        <div class="text">{{ lastList[val - 1].num }}</div>
        字 (可兑换)
      </div>
      <!-- <div class="isExtractSum" v-show="val != 1">
        <i class="last-box-icon icon_cahracter"></i>

        可提交的字符：
        <div class="text">{{ isExtractSum }}</div>
        字
      </div> -->
      <div class="shape">
        <i class="last-box-icon icon_shape"></i>

        选择{{ val != 1 ? "兑现" : "提取" }}方式
      </div>

      <div class="checked">
        <el-checkbox v-model="checked" @change="handleChecked(1)">
          全部{{ val != 1 ? "兑现" : "提取" }}（
          <span class="text">{{
            val == 1
              ? lastList[val - 1].num
              : ((lastList[val - 1].num / 10000) * 2.5).toFixed(2)
          }}</span>
          元 ）
        </el-checkbox>
        <el-checkbox
          v-if="val != 1"
          v-model="inputshow"
          @change="handleChecked(2)"
        >
          自定义{{ val != 1 ? "兑现" : "提取"
          }}<span v-show="input && val != 1"> （</span>
          <span class="text" v-show="input && val != 1">{{
            ((input / 10000) * 2.5).toFixed(2)
          }}</span>

          <span v-show="input && val != 1"> 元 ）</span>
        </el-checkbox>

        <el-input
          v-show="inputshow"
          v-model="input"
          type="Number"
          @change="handleSumChange"
          :placeholder="
            val == 1
              ? '请输入金额，超出现有金额视为全部提取'
              : '请输入字符，超出现有字符视为全部提取'
          "
        ></el-input>
      </div>

      <div class="shape" v-show="val == 1">
        <i class="last-box-icon icon_shape"></i>
        选择到账方式
      </div>

      <div class="radio" v-show="val == 1">
        <el-checkbox-group
          v-model="wayList"
          @change="handleCheckedCitiesChange"
        >
          <!-- <el-checkbox label="提现至微信"></el-checkbox> -->
          <el-checkbox label="提现至支付宝"></el-checkbox>
          <!-- <el-checkbox label="提现至银行卡"></el-checkbox> -->
        </el-checkbox-group>

        <div class="txt">账号实名</div>
        <el-input
          v-model="alipayName"
          placeholder="请输入提现账号实名"
        ></el-input>
        <div class="txt">提现账号</div>
        <el-input
          v-model="alipayAccount"
          placeholder="请输入提现账号"
        ></el-input>
      </div>

      <div class="shape" v-show="val == 1">
        提现须知：预计将于1个工作日内到账。
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="redeemedForCash">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { exchangeMoney, addCashMoney } from "api/copyrightIncome";
export default {
  name: "AccountRecordsDialog",
  props: {
    centerList: {
      type: Array,
      default: () => [],
    },
    lastList: {
      type: Array,
      default: () => [],
    },
    systemCHSum: {
      default: () => [],
    },
  },
  data() {
    return {
      //1积分  2排名
      centerVal: 1,
      //显示积分/排名
      centerVisible: false,
      // 提现显示隐藏
      dialogVisible: false,
      //1.提现 2.兑现
      val: 1,
      wayList: ["提现至支付宝"],
      checked: true,
      inputshow: false, // 输入框显示隐藏
      input: "", // 提现金额
      alipayName: "", //支付宝姓名
      alipayAccount: "", //支付宝账户
    };
  },
  methods: {
    // 字符提现或者现金提现弹窗关闭回调
    handleClose() {
      // this.isShow = !this.isShow;
      this.input = "";
      this.checked = true;
      this.inputshow = false;
      this.alipayName = "";
      this.alipayAccount = "";
    },
    // 输入金额  输入字符 inputChange 事件
    handleSumChange() {
      if (this.input > this.lastList[this.val - 1].num && this.val === 1) {
        this.input = this.lastList[this.val - 1].num;
      } else if (
        this.input > this.lastList[this.val - 1].num &&
        this.val === 2
      ) {
        this.input = this.lastList[this.val - 1].num;
      }
    },
    // 自定义提现额度或者全额
    handleChecked(val) {
      val == 1
        ? ((this.checked = true), (this.inputshow = false))
        : ((this.checked = false), (this.inputshow = true));
    },
    // 提现类型
    handleCheckedCitiesChange(val) {
      this.wayList = [val[val.length - 1]];
    },
    async redeemedForCash() {
      this.$store.commit("CHECK_USER_OPTIONS", {
        afterCheck: async () => {
          let money;
          if (this.checked && this.val == 1) {
            money = this.lastList[this.val - 1].num;
          } else if (!this.checked && this.val == 1) {
            money = this.input;
          } else if (this.checked && this.val != 1) {
            money = ((this.lastList[this.val - 1].num / 10000) * 2.5).toFixed(
              2
            );
          } else if (!this.checked && this.val != 1) {
            money = ((this.input / 10000) * 2.5).toFixed(2);
          }

          if (money < 1) {
            return this.$message({
              type: "error",
              message: "提取金额不能小于1",
            });
          }

          if (this.val === 1) {
            if (!this.alipayName) {
              return this.$message({
                type: "error",
                message: "请输入账号实名!",
              });
            }

            if (!this.alipayAccount) {
              return this.$message({
                type: "error",
                message: "请输入账号!",
              });
            }

            const data = {
              extractType: this.checked ? 1 : 2,
              extractNum: money,
              arriveType: "2",
              realName: this.alipayName,
              arriveAccount: this.alipayAccount,
            };
            const res = await addCashMoney(data);
            if (res.code === 200) {
              this.alipayName = "";
              this.alipayAccount = "";
              this.checked = true;
              this.inputshow = false;
              this.input = "";
              this.dialogVisible = false;
              this.$message({
                type: "success",
                message: "提现成功",
              });
            } else {
              this.$message({
                type: "error",
                message: "提现失败",
              });
            }
          } else {
            const data = {
              ptype: this.val,
              money,
            };

            const res = await exchangeMoney(data);
            if (res.code === 200) {
              this.checked = true;
              this.input = "";
              this.inputshow = false;
              this.dialogVisible = false;
              this.$message({
                type: "success",
                message: "兑换成功",
              });
            } else {
              this.$message({
                type: "error",
                message: "兑换失败",
              });
            }
          }
          this.$emit("updateRevenue");
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.account-records-dialog {
  .treasury-group-dialog {
    .center {
      .title {
        font-weight: 800;
        font-size: 16px;
        justify-content: center;
        display: flex;
        align-items: center;
        .icon_ranking {
          width: 19px;
          height: 19px;
          margin-right: 6px;
          @include backgroundGroup("../../assets/images/ranking.png");
        }

        .integral {
          display: flex;
        }
        .icon_integral {
          width: 19px;
          height: 19px;
          margin-right: 6px;
          @include backgroundGroup("../../assets/images/integral.png");
        }
        .text {
          color: #498aff;
          margin: 0 10px;
        }
      }
      .text_ranking {
        margin-top: 15px;
        line-height: 30px;
      }
    }
    .btn_box {
      display: flex;
      color: #4587ff;
      font-size: 16px;
      justify-content: space-between;

      .placeholder {
        width: 1px;
        height: 20px;
        background-color: #e8e8e8;
      }
      .confirm,
      .cancel {
        flex: 48%;
        cursor: pointer;
      }
    }
  }
  .withdraw-dialog {
    .cahracter {
      margin-bottom: 15px;
      font-size: 14px;
      display: flex;
      align-items: center;
      .icon_cahracter {
        width: 14px;
        height: 14px;
        margin-right: 6px;
        @include backgroundGroup("../../assets/images/cahracter.png");
      }
      .text {
        color: #498aff;
        font-size: 24px;
        margin-right: 6px;
        font-weight: 800;
      }
    }
    .cash {
      font-size: 14px;
      display: flex;
      align-items: center;
      .icon_cash {
        width: 14px;
        height: 14px;
        margin-right: 6px;
        @include backgroundGroup("../../assets/images/cash.png");
      }
      .text {
        color: #eb441e;
        font-size: 24px;
        margin-right: 6px;
        font-weight: 800;
      }
    }
    .shape {
      margin-top: 20px;
      font-size: 14px;
      display: flex;
      align-items: center;
      .icon_shape {
        width: 14px;
        height: 14px;
        margin-right: 6px;
        @include backgroundGroup("../../assets/images/shape.png");
      }
    }
    .radio {
      .txt {
        margin: 10px 0;
      }
      .el-input {
        margin: 10px 0;
      }
    }
    .checked {
      .el-checkbox {
        margin: 20px 0 0 20px;
      }

      .el-input {
        margin-top: 20px;
        ::v-deep .el-input__inner::-webkit-outer-spin-button,
        ::v-deep .el-input__inner::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        ::v-deep .el-input__inner[type="number"] {
          -moz-appearance: textfield;
        }
      }
      display: flex;
      flex-direction: column;

      .text {
        color: #eb441e;
      }
    }
  }
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
    .el-checkbox {
      margin: 20px 0 0 20px;
    }
  }
}
</style>
